import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { FilterCriteria } from '@src/components/FilterCriteria';
import { Table as TableComponent } from '@src/components/Table-static';
import PropTypes from 'prop-types';
import { Styles } from './includes/styles';
import configuration from './includes/configuration.json';

/**
 * Table: CommonCriteria
 *
 * Displays a list of common criteria filters within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { rows, filterKey, form } = props;

  const [checked, setChecked] = useState({});

  /**
   * map the rows to include the checkbox state.
   */
  const theRows = useMemo(() => {
    return rows?.map((row) => {
      return {
        ...row,
        ...{
          checkbox: checked[row?.id] || false,
        },
      };
    });
  }, [rows, checked]);

  useEffect(() => {
    const commonCriteriaIds = Object.keys(checked).filter((commonCriteriaId) => {
      return checked[commonCriteriaId] === true;
    });

    form.setValue(filterKey, commonCriteriaIds);
  }, [checked]);

  /**
   * renderCellCheckbox
   *
   * Displays a checkbox in a cell indicating whether the common criteria filters are applied.
   *
   * @param args
   * @returns {Element}
   */
  const renderCellCheckbox = (args = {}) => {
    const { row } = args;

    return (
      <Checkbox
        label=""
        isChecked={row?.original?.checkbox || false}
        onChange={(event) => {
          setChecked((previousState) => {
            return { ...previousState, ...{ [row?.original?.id]: event?.target?.checked } };
          });
        }}
      />
    );
  };

  /**
   * renderCellName
   *
   * Displays the name of the common criteria filter.
   *
   * @param args
   * @returns {*}
   */
  const renderCellName = (args = {}) => {
    const { cell } = args;
    return cell?.value;
  };

  /**
   * renderCellCriteria
   *
   * Displays the common criteria filters.
   *
   * @param args
   * @returns {Element}
   */
  const renderCellCriteria = (args = {}) => {
    const { row } = args;
    return <FilterCriteria filters={row?.original?.criteria} showLabel={false} />;
  };

  /**
   * Columns for the table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns?.map((column) => {
      const theColumn = column;

      if (theColumn.accessor === 'checkbox') {
        theColumn.Cell = renderCellCheckbox;
      }

      if (theColumn.Header === 'Name') {
        theColumn.Cell = renderCellName;
      }

      if (theColumn.Header === 'Criteria') {
        theColumn.Cell = renderCellCriteria;
      }

      return theColumn;
    });
  }, []);

  return (
    <ErrorHandler location="src/widgets/CommonCriteria/components/Table/Table.jsx">
      <Styles>
        <TableComponent
          {...{
            rows: theRows,
            columns,
            configuration,
            dataKey: `actionPaths-${filterKey}`,
          }}
        />
      </Styles>
    </ErrorHandler>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      checkbox: PropTypes.bool,
      criteria: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        })
      ),
      name: PropTypes.string,
    })
  ),
  filterKey: PropTypes.string,
  form: PropTypes.shape({
    setValue: PropTypes.func,
  }),
};

Table.defaultProps = {
  rows: [],
  filterKey: '',
  form: {
    setValue: () => {},
  },
};
