import { useApi } from '@src/context/Api';
import { useCallback } from 'react';
import { useToast } from '@abyss/web/hooks/useToast';
import { config } from '@abyss/web/tools/config';

/**
 * useSave
 *
 * Makes an API request to save the common criteria.
 *
 * @returns {(function(string=, {}=): Promise<void>)|*}
 */
export const useSave = () => {
  const { useApiMutation, clearApiCache } = useApi();
  const [SaveCommonCriteria] = useApiMutation('SaveCommonCriteria');

  const { toast } = useToast();

  return useCallback(async (mode = '', submittedValues = {}) => {
    try {
      let text;

      if (mode === 'create') {
        text = 'Creat';
      }

      if (mode === 'update') {
        text = 'Sav';
      }

      const toastId = `common-criteria-${text.toLowerCase()}e`;

      toast.show({
        id: `${toastId}-info`,
        title: `${text}ing Common Criteria...`,
        message: `Preparing to ${text.toLowerCase()}e the common criteria.`,
        isLoading: true,
        variant: 'info',
        autoClose: false,
      });

      await SaveCommonCriteria(submittedValues, {
        onSuccess: () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-success`,
            title: `${text}ed Common Criteria`,
            message: `${submittedValues?.name} has been ${text.toLowerCase()}ed.`,
            variant: 'success',
          });
          clearApiCache(['ListCommonCriteria']);
        },
        onError: () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-error`,
            title: `${text}ing Common Criteria Failed`,
            message: `Unable to ${text}e ${submittedValues?.name}.`,
            variant: 'error',
          });
        },
      });
    } catch (error) {
      let theError = error;

      if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
        theError = JSON.stringify(error);
      }

      console.error('src/routes/private/Admin/screens/CommonCriteria/hooks/useSave.js -> useSave():', theError);
    }
  }, []);
};
