import React, { useEffect, useState } from 'react';
import { Accordion } from '@abyss/web/ui/Accordion';
import { Alert } from '@abyss/web/ui/Alert';
import { Badge } from '@abyss/web/ui/Badge';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isEmpty } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Loader } from '@src/components/Loader';
import { motion } from 'framer-motion';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import { useApi } from '@src/context/Api';
import { useRoutesContext } from '@src/context/Routes';
import { Visibility } from '@src/components/Visibility';
import { Link } from '@abyss/web/ui/Link';
import { Styles } from './includes/styles';
import { EditForm } from './components/forms/Edit';
import { CreateModal } from './components/modals/Create';

/**
 * CommonCriteria
 *
 * Provides the user with a screen listing the common criteria to be used for filtering risk records.
 *
 * @returns {Element}
 * @constructor
 */
export const CommonCriteria = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const { currentRoute } = useRoutesContext();

  const { useApiQuery } = useApi();
  const [ListCommonCriteria, { data, isLoading, isFetching, refetch }] = useApiQuery('ListCommonCriteria');

  /**
   * Fetch the common criteria data if it is not already loaded.
   */
  useEffect(() => {
    const payload = {
      page: 0,
      size: 9999,
      sort: 'name,asc',
    };

    if (showAll === false) {
      payload.isActive = true;
    }

    ListCommonCriteria(payload);
  }, [showAll]);

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CommonCriteria/CommonCriteria.jsx">
      {/* @TODO: temporary - remove Visibility Wrapper when ready (Feature: CommonCriteria) */}
      <Visibility
        accessor={currentRoute?.accessor}
        enabledEnvironments={['Local', 'Development']}
        disabledEnvironments={['Stage', 'Production']}
      >
        <motion.div
          animate="open"
          variants={{
            open: { opacity: 1 },
            closed: { opacity: 0 },
          }}
          initial={{ opacity: 0 }}
        >
          <Styles>
            <Grid>
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <Flex direction="row" alignItems="center">
                  <Heading offset={0}>Common Criteria</Heading>
                  <Button
                    before={<IconSymbol icon="add" />}
                    css={{
                      marginLeft: 'var(--abyss-space-md)',
                    }}
                    size="$sm"
                    variant="outline"
                    onClick={() => {
                      setIsCreateModalOpen(true);
                    }}
                  >
                    Create
                  </Button>
                </Flex>
              </Grid.Col>

              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <ToggleSwitch
                  label="Show all Criteria"
                  isChecked={showAll}
                  onChange={(event) => {
                    return setShowAll(event?.target?.checked);
                  }}
                />
              </Grid.Col>

              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                {isLoading || isFetching ? (
                  <Loader verticalAlignment="top" />
                ) : (
                  <React.Fragment>
                    {isEmpty(data?.content) ? (
                      <React.Fragment>
                        {showAll === true && (
                          <Alert
                            title={
                              <Layout.Group>
                                There is no Common Criteria.
                                <Link
                                  size="$sm"
                                  href={currentRoute?.path}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    setIsCreateModalOpen(true);
                                  }}
                                  after={<IconSymbol icon="double_arrow" css={{ fontSize: 'inherit !important' }} />}
                                >
                                  Create Common Criteria
                                </Link>
                              </Layout.Group>
                            }
                            variant="info"
                          />
                        )}
                        {showAll === false && (
                          <Alert
                            title={
                              <Layout.Group>
                                There is no activated Common Criteria.
                                <Link
                                  size="$sm"
                                  href={currentRoute?.path}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    setShowAll(true);
                                  }}
                                  after={<IconSymbol icon="double_arrow" css={{ fontSize: 'inherit !important' }} />}
                                >
                                  Show all Criteria
                                </Link>
                              </Layout.Group>
                            }
                            variant="info"
                          />
                        )}
                      </React.Fragment>
                    ) : (
                      <Accordion isCollapsible>
                        {data?.content?.map((item) => {
                          return (
                            <Accordion.Item key={`${item?.name}`} value={item?.id}>
                              <Accordion.Trigger>
                                <Accordion.Header>
                                  <Heading offset={3} color="var(--abyss-colors-gray7)">
                                    <Layout.Group>
                                      {item?.name}

                                      {item?.isActive === true && (
                                        <Badge variant="success" outline>
                                          Active
                                        </Badge>
                                      )}

                                      {item?.isActive === false && (
                                        <Badge variant="error" outline>
                                          Inactive
                                        </Badge>
                                      )}
                                    </Layout.Group>
                                  </Heading>
                                </Accordion.Header>
                              </Accordion.Trigger>
                              <Accordion.Content>
                                <EditForm currentEntity={item} />
                              </Accordion.Content>
                            </Accordion.Item>
                          );
                        })}
                      </Accordion>
                    )}
                  </React.Fragment>
                )}
                {isCreateModalOpen && (
                  <CreateModal isOpen={isCreateModalOpen} refetch={refetch} setIsOpen={setIsCreateModalOpen} />
                )}
              </Grid.Col>
            </Grid>
          </Styles>
        </motion.div>
      </Visibility>
      {/* @TODO: temporary - remove Visibility Wrapper when ready (Feature: CommonCriteria) */}
    </ErrorHandler>
  );
};
