import PropTypes from 'prop-types';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import { HorizontalBarChart } from './components/HorizontalBarChart';
import { Styles } from './includes/styles';

/**
 * Widget: UntrustedSourceRecords
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const UntrustedSourceRecords = (props) => {
  const { data } = props;

  return (
    <ErrorHandler location="src/widgets/UntrustedSourceRecords/UntrustedSourceRecords.jsx">
      <Styles>
        <Widget
          title="Untrusted Source Records"
          description="Records by source that are with or without an action path"
        >
          <HorizontalBarChart data={data} />
        </Widget>
      </Styles>
    </ErrorHandler>
  );
};

UntrustedSourceRecords.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      total: PropTypes.number,
    })
  ),
};

UntrustedSourceRecords.defaultProps = {
  data: [],
};
