import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@abyss/web/ui/Box';
import { Chart } from '@src/components/Chart';
import { chartTypes } from '@src/components/Chart/includes/chartTypes';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty, sortBy } from 'lodash';
import { RiskRecords as RiskRecordsTable } from '@src/tables';
import { Widget } from '@src/components/Widget';
import { Styles } from './includes/styles';

/**
 * Widget: AggregationView
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const AggregationView = (props) => {
  const { aggregation, view, filters } = props;

  const [currentChartType, setCurrentChartType] = useState(aggregation?.researchAggregation?.defaultChartType);
  const [data, setData] = useState(aggregation?.aggregationResults);

  let widgetMenuOptions = [];

  if (!view?.id?.includes('risk-records')) {
    widgetMenuOptions = [
      {
        title: 'View as...',
        label: 'View as...',
        onChange: setCurrentChartType,
        value: currentChartType,
        radios: aggregation?.researchAggregation?.chartTypes.map((chartType) => {
          return {
            label: chartTypes?.[chartType]?.label,
            value: chartType,
          };
        }),
      },
    ];
  }

  /**
   * Custom sorting based on view.
   */
  useEffect(() => {
    let theData = aggregation?.aggregationResults;

    if (['last-reopened-date', 'last-streamed-date'].includes(view?.id)) {
      const order = ['1 week', '1 week - 1 month', '1 month - 3 months', '3 months - 9 months', '> 9 months'];

      theData = sortBy(aggregation?.aggregationResults, (item) => {
        return order.indexOf(String(item.name).toLowerCase());
      });
    }

    if (data !== theData) {
      setData(theData);
    }
  }, [view?.id, aggregation?.aggregationResults]);

  /**
   * tableColumns
   *
   * Map the table columns based on the aggregation data.
   *
   * @type {*[]}
   */
  const tableConfiguration = useMemo(() => {
    const theConfiguration = {
      initialColumns: [],
      initialState: {
        sortBy: [
          {
            id: 'value',
            desc: true,
          },
        ],
      },
    };

    if (!isEmpty(aggregation?.researchAggregation?.dataTableColumnHeaders)) {
      Object.keys(aggregation?.researchAggregation?.dataTableColumnHeaders).forEach((accessor) => {
        theConfiguration.initialColumns.push({
          Header: aggregation?.researchAggregation?.dataTableColumnHeaders?.[accessor],
          accessor,
          /* eslint-disable react/prop-types */
          Cell: ({ cell }) => {
            // Formats number to display with commas.
            if (accessor === 'value') {
              return <React.Fragment>{Number(cell?.value).toLocaleString('en-US')}</React.Fragment>;
            }

            // Formats a number to display as a decimal.
            if (accessor === 'perc') {
              return <React.Fragment>{cell?.value}%</React.Fragment>;
            }

            return cell?.value;
          },
        });
      });
    }

    return theConfiguration;
  }, [aggregation?.researchAggregation?.dataTableColumnHeaders]);

  return (
    <ErrorHandler location="src/widgets/AggregationView/AggregationView.jsx">
      <Styles css={view?.id === 'risk-records' && { width: '100%' }}>
        <Box key={view?.id} height="" color="">
          <Widget title={view?.name || ''} description={view?.description || ''} menu={widgetMenuOptions || []}>
            {view?.id === 'risk-records' ? (
              <RiskRecordsTable
                allowExport={false}
                entranceCriteria={filters}
                showHeaderTop={false}
                showHeaderLeft={false}
              />
            ) : (
              <Chart type={currentChartType} data={data} tableConfiguration={tableConfiguration} legendLimit={15} />
            )}
          </Widget>
        </Box>
      </Styles>
    </ErrorHandler>
  );
};

AggregationView.propTypes = {
  aggregation: PropTypes.shape({
    researchAggregation: PropTypes.shape({
      chartTypes: PropTypes.arrayOf(PropTypes.string),
      defaultChartType: PropTypes.string,
      dataTableColumnHeaders: PropTypes.shape({
        accessor: PropTypes.string,
      }),
    }),
    aggregationResults: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
        perc: PropTypes.number,
      })
    ),
  }),
  assets: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.string,
      condition: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    })
  ),
  view: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
};

AggregationView.defaultProps = {
  aggregation: {
    researchAggregation: {
      chartTypes: [],
      defaultChartType: '',
      dataTableColumnHeaders: {},
    },
    aggregationResults: [],
  },
  assets: {
    id: '',
    name: '',
    description: '',
  },
  filters: [],
  view: {
    id: '',
    name: '',
    description: '',
  },
};
