import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@src/components/Button';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Filters } from '@src/components/Filters';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { isUndefined, range } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { motion } from 'framer-motion';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { useForm } from '@abyss/web/hooks/useForm';
import { DeactivationModal } from '../../modals/Deactivation';
import { useSave } from '../../../hooks/useSave';
import { useUpdateStatus } from '../../../hooks/useUpdateStatus';

/**
 * form: Edit
 *
 * Displays a form to update a set of common criteria filters.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Edit = (props) => {
  const { currentEntity } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [showDeactivationModal, setShowDeactivationModal] = useState(false);

  const handleUpdateStatus = useUpdateStatus();
  const handleSave = useSave();

  const defaultValues = {
    id: '',
    name: '',
    version: 0,
    filters: [],
  };

  const form = useForm({ defaultValues });

  const { isDirty, isValid, isSubmitting } = form?.formState;

  /**
   * Mapping currentEntity loaded from API to the form state.
   */
  useEffect(() => {
    const data = defaultValues;

    data.id = currentEntity?.id;
    data.name = currentEntity?.name;
    data.version = currentEntity?.version;

    if (!isUndefined(currentEntity?.criteria)) {
      data.filters = currentEntity?.criteria.map((filter) => {
        const theFilter = { ...filter };

        if (['LAST_MODIFIED_DATE', 'CREATED_DATE'].includes(theFilter?.column)) {
          theFilter.value = dayjs(theFilter?.value).format('MM/DD/YYYY');
        }

        if (theFilter?.column?.toLowerCase().includes('count')) {
          theFilter.value = String(theFilter?.value);
        }

        return theFilter;
      });
    }

    form?.reset(data, {
      keepDirty: false,
      keepDirtyValues: false,
      keepErrors: false,
      keepIsValid: false,
      keepSubmitCount: true,
      keepTouched: false,
      keepValues: false,
    });
  }, [currentEntity]);

  /**
   * handleSubmit
   *
   * Calls a remote API to save the common criteria.
   *
   * @returns {Promise<void>}
   */
  const handleSubmit = useCallback(
    async (submittedValues) => {
      if (!isLoading && isValid && !isSubmitting && isDirty) {
        setIsLoading(true);
        await handleSave('update', submittedValues);
        setIsLoading(false);
      }
    },
    [isLoading, isDirty, isValid, isSubmitting, form?.formState?.errors]
  );

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CommonCriteria/components/forms/Edit/Edit.jsx">
      <motion.div
        animate="open"
        variants={{
          open: { opacity: 1 },
          closed: { opacity: 0 },
        }}
        initial={{ opacity: 0 }}
      >
        <FormProvider state={form} autoComplete="off" highlighted onSubmit={handleSubmit}>
          <Grid>
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              <Filters
                form={form}
                isDisabled={currentEntity?.isActive === false}
                isLoading={isLoading}
                headerLeft={
                  <div style={{ width: 'auto' }}>
                    <SelectInput
                      label="Version"
                      placeholder="Please Select --"
                      model="version"
                      options={range(0, 10).map((version) => {
                        return { label: version, value: version };
                      })}
                    />
                  </div>
                }
              />
            </Grid.Col>
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              <Layout.Group alignLayout="right">
                {currentEntity?.isActive === true && (
                  <Button
                    variant="destructive"
                    onClick={() => {
                      setShowDeactivationModal(true);
                    }}
                  >
                    Deactivate
                  </Button>
                )}

                {currentEntity?.isActive === false && (
                  <Button
                    variant="outline"
                    onClick={async () => {
                      await handleUpdateStatus({
                        ...currentEntity,
                        ...form?.getValues(),
                        ...{
                          isActive: true,
                        },
                      });
                    }}
                  >
                    Activate
                  </Button>
                )}

                <Button
                  variant="solid"
                  type="submit"
                  isDisabled={currentEntity?.isActive === false || !isDirty || !isValid}
                >
                  Save
                </Button>
              </Layout.Group>
            </Grid.Col>
          </Grid>
        </FormProvider>
        {showDeactivationModal && (
          <DeactivationModal
            currentEntity={currentEntity}
            isOpen={showDeactivationModal}
            setIsOpen={setShowDeactivationModal}
          />
        )}
      </motion.div>
    </ErrorHandler>
  );
};

Edit.propTypes = {
  currentEntity: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    version: PropTypes.number,
    criteria: PropTypes.arrayOf(
      PropTypes.shape({
        column: PropTypes.string,
        condition: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
      })
    ),
    isActive: PropTypes.bool,
  }),
};

Edit.defaultProps = {
  currentEntity: {},
};
