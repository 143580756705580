import PropTypes from 'prop-types';
import React from 'react';
import { AnalyzeRisksButton } from '@src/components/AnalyzeRisksButton';
import { FilterCriteria as Table } from '@src/tables';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useRoutesContext } from '@src/context/Routes';
import { Widget } from '@src/components/Widget';
import { Styles } from './includes/styles';

/**
 * Widget: EntranceCriteria
 *
 * @TODO Needs description
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const EntranceCriteria = (props) => {
  const { filters } = props;

  const { currentRoute } = useRoutesContext();

  return (
    <ErrorHandler location="src/widgets/EntranceCriteria/EntranceCriteria.jsx">
      <Styles>
        <Widget
          title="Entrance Criteria"
          description="The following filters are applied:"
          button={
            !['view-action-path'].includes(currentRoute?.slug) && (
              <AnalyzeRisksButton entranceCriteria={filters} variant="button" />
            )
          }
        >
          <Table rows={filters || []} />
        </Widget>
      </Styles>
    </ErrorHandler>
  );
};

EntranceCriteria.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

EntranceCriteria.defaultProps = {
  filters: [],
};
