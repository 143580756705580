import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-accordion-root': {
    border: '1px solid var(--abyss-colors-gray4)',
    borderRadius: '0 !important',
    '.abyss-accordion-item': {
      borderColor: 'var(--abyss-colors-gray4)',
      borderRadius: '0 !important',
      '&[data-state="closed"]': {
        borderBottom: '1px solid var(--abyss-colors-gray4)',
      },
      '&:last-child': {
        borderBottom: 'none',
        '.abyss-accordion-content-container': {
          borderBottom: 'none',
        },
      },
      '.abyss-accordion-trigger': {
        height: '64px',
        backgroundColor: 'var(--abyss-colors-info2) !important',
        boxShadow: 'none',
        borderBottom: '1px solid var(--abyss-colors-gray4)',
        '&:last-child': {
          borderBottom: 'none',
        },
      },
      '.abyss-accordion-content-container': {
        borderTop: '1px solid var(--abyss-colors-gray4)',
        borderBottom: '1px solid var(--abyss-colors-gray4)',
      },
    },
  },
});
