import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Filters } from '@src/components/Filters';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { isEmpty } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import { Visibility } from '@src/components/Visibility';
import { CommonCriteria } from '@src/widgets/CommonCriteria';
import { AdditionalCriteria } from '@src/widgets/AdditionalCriteria';
import { Styles } from './includes/styles';

/**
 * ExitCriteria
 *
 * Provides the user with a screen to specify a list of filters to find risk records by.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ExitCriteria = (props) => {
  const { assets, currentStep, form, isLoading } = props;

  const [hasExitCriteria, setHasExitCriteria] = useState(null);
  const [isEnabled, setIsEnabled] = useState(null);

  const actionPaths = assets?.ListActionPaths?.data || [];
  const actionStatuses = assets?.ListActionStatuses?.data || [];
  const tags = assets?.ListTags?.data || [];

  const remediationValues = form?.getValues('remediation');

  /**
   * Exit criteria is only available if the remediation has an entity source.
   */
  useEffect(() => {
    if (!isEmpty(remediationValues?.assignments)) {
      const hasEntitySource = remediationValues?.assignments.some((assignment) => {
        return assignment?.impactedSource === 'ENTITY';
      });

      setHasExitCriteria(hasEntitySource);
    }
  }, [remediationValues]);

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/components/Wizard/steps/ExitCriteria/ExitCriteria.jsx">
      <Visibility
        accessor="ExitCriteria"
        enabledEnvironments={['Local', 'Development']}
        disabledEnvironments={['Stage', 'Production']}
      >
        <Styles>
          <Grid>
            <Grid.Col
              css={{ paddingTop: themeConfiguration?.theme?.space?.lg }}
              span={{
                xs: '100%',
              }}
            >
              <Layout.Stack space={0} grow>
                <Layout.Group space={themeConfiguration?.theme?.space?.md}>
                  <Heading offset={1}>{currentStep?.label}</Heading>
                  <ToggleSwitch
                    label=""
                    isChecked={isEnabled}
                    onChange={(event) => {
                      return setIsEnabled(event?.target?.checked);
                    }}
                    isDisabled={!hasExitCriteria}
                  />
                </Layout.Group>
                <p>{currentStep?.description}</p>
              </Layout.Stack>
            </Grid.Col>

            {isEnabled && [
              <Visibility
                accessor="FilterCriteria-current"
                enabledEnvironments={['Stage', 'Production']}
                disabledEnvironments={['Local', 'Development']}
              >
                <Grid.Col id="filtersContainer" span={{ xs: '100%' }}>
                  <Filters
                    actionPaths={actionPaths}
                    actionStatuses={actionStatuses}
                    filterKey="exitCriteria"
                    form={form}
                    isLoading={isLoading}
                    tags={tags}
                  />
                </Grid.Col>
              </Visibility>,

              <Visibility
                accessor="CommonCriteria"
                enabledEnvironments={['Local', 'Development']}
                disabledEnvironments={['Stage', 'Production']}
              >
                <Grid.Col span={{ xs: '100%' }}>
                  <CommonCriteria filterKey="commonExitCriteria" />
                </Grid.Col>
                <Grid.Col span={{ xs: '100%' }}>
                  <AdditionalCriteria
                    actionPaths={actionPaths}
                    actionStatuses={actionStatuses}
                    filterKey="exitCriteria"
                    form={form}
                    isLoading={isLoading}
                    tags={tags}
                  />
                </Grid.Col>
              </Visibility>,
            ]}
          </Grid>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

ExitCriteria.propTypes = {
  assets: PropTypes.shape({
    ListActionPaths: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
    ListActionStatuses: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
    ListTags: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
  }),
  currentStep: PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.string,
  }),
  form: PropTypes.shape({
    getValues: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
};

ExitCriteria.defaultProps = {
  assets: {},
  currentStep: {},
  form: {},
  isLoading: false,
};
