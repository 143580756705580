import { isEmpty } from 'lodash';
import { config } from '@abyss/web/tools/config';

/**
 * validateManualStep
 *
 * validates the current step of the wizard for manual actions
 *
 * @param currentStep
 * @param validName
 * @param formErrors
 * @returns {*|boolean}
 */
function validateManualStep(currentStep, validName, formErrors) {
  const theStep = { ...currentStep };

  if (theStep?.order === 1) {
    theStep.isValid = validName && isEmpty(formErrors?.remediation);
  }

  if (theStep?.order === 2) {
    theStep.isValid = validName && isEmpty(formErrors?.remediation);
  }

  return theStep.isValid;
}

/**
 * validateAutomaticStep
 *
 * validates the current step of the wizard for automatic actions
 *
 * @param currentStep
 * @param validName
 * @param formErrors
 * @returns {*}
 */
function validateAutomaticStep(currentStep, validName, formErrors) {
  const theStep = { ...currentStep };

  if (theStep?.order === 1) {
    theStep.isValid = validName && !!isEmpty(formErrors?.entranceCriteria);
  }

  if (theStep?.order === 2) {
    theStep.isValid = validName;
  }

  if (theStep?.order === 3) {
    theStep.isValid = validName && !!isEmpty(formErrors?.remediation);
  }

  // @TODO: temporary - remove when ready (Feature: ExitCriteria)
  if (['local', 'local:dev', 'local:build', 'dev'].includes(config('APP_ENV')) && [4, 5].includes(theStep?.order)) {
    theStep.isValid = validName && !!isEmpty(formErrors?.entranceCriteria) && !!isEmpty(formErrors?.remediation);
  }

  if (['stage', 'prod'].includes(config('APP_ENV')) && theStep?.order === 4) {
    theStep.isValid = validName && !!isEmpty(formErrors?.entranceCriteria) && !!isEmpty(formErrors?.remediation);
  }
  // @TODO: temporary - remove when ready (Feature: ExitCriteria)

  /*
    if (theStep?.order === 4) {
      theStep.isValid = validName && !!isEmpty(formErrors?.entranceCriteria) && !!isEmpty(formErrors?.remediation);
    }
  */
  return theStep.isValid;
}

/**
 * isValidStep
 *
 * validates the current step of the wizard
 *
 * @param currentStep
 * @param validName
 * @param formErrors
 * @param isManual
 * @returns {*|boolean}
 */
export function isValidStep(currentStep, validName, formErrors, isManual) {
  const theStep = { ...currentStep };

  if (isManual) {
    theStep.isValid = validateManualStep(currentStep, validName, formErrors);
  } else {
    theStep.isValid = validateAutomaticStep(currentStep, validName, formErrors);
  }

  return theStep.isValid;
}
