import PropTypes from 'prop-types';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Filters } from '@src/components/Filters';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Visibility } from '@src/components/Visibility';
import { CommonCriteria } from '@src/widgets/CommonCriteria';
import { AdditionalCriteria } from '@src/widgets/AdditionalCriteria';
import { Styles } from './includes/styles';

/**
 * EntranceCriteria
 *
 * Provides the user with a screen to specify a list of filters to find risk records by.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const EntranceCriteria = (props) => {
  const { currentStep, form } = props;

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/components/Wizard/steps/EntranceCriteria/EntranceCriteria.jsx">
      <Styles>
        <Grid>
          <Grid.Col css={{ paddingTop: 'var(--abyss-space-lg)' }} span={{ xs: '100%' }}>
            <Heading offset={1}>{currentStep?.label}</Heading>
            <p>{currentStep?.description}</p>
          </Grid.Col>

          <Visibility
            accessor="FilterCriteria-current"
            enabledEnvironments={['Stage', 'Production']}
            disabledEnvironments={['Local', 'Development']}
          >
            <Grid.Col id="filtersContainer" span={{ xs: '100%' }}>
              <Filters filterKey="criteria.entrance.additional" form={form} />
            </Grid.Col>
          </Visibility>

          <Visibility
            accessor="CommonCriteria"
            enabledEnvironments={['Local', 'Development']}
            disabledEnvironments={['Stage', 'Production']}
          >
            <Grid.Col span={{ xs: '100%' }}>
              <CommonCriteria form={form} filterKey="criteria.entrance.common" />
            </Grid.Col>
            <Grid.Col span={{ xs: '100%' }}>
              <AdditionalCriteria form={form} filterKey="criteria.entrance.additional" />
            </Grid.Col>
          </Visibility>
        </Grid>
      </Styles>
    </ErrorHandler>
  );
};

EntranceCriteria.propTypes = {
  assets: PropTypes.shape({
    ListActionPaths: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
    ListActionStatuses: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
    ListTags: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
  }),
  currentStep: PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.string,
  }),
  form: PropTypes.shape({
    getValues: PropTypes.func,
  }),
};

EntranceCriteria.defaultProps = {
  assets: {},
  currentStep: {},
  form: {},
};
