import PropTypes from 'prop-types';
import React from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { EntranceCriteria } from '@src/widgets/EntranceCriteria';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { FilterCriteria } from '@src/widgets/FilterCriteria';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { RemediationActions } from '@src/widgets/RemediationActions';
import { Visibility } from '@src/components/Visibility';

/**
 * ReviewConfirm
 *
 * Provides the user with a screen to verify the specified entrance criteria (filters) and actions to run on the
 * queried risk records.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ReviewConfirm = (props) => {
  const { currentStep, form, isManual, assets } = props;

  const formValues = form.getValues();

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/components/Wizard/steps/ReviewConfirm/ReviewConfirm.jsx">
      <Visibility
        accessor="FilterCriteria-current"
        enabledEnvironments={['Stage', 'Production']}
        disabledEnvironments={['Local', 'Development']}
      >
        <Grid>
          <Grid.Col
            css={{ paddingTop: themeConfiguration?.theme?.space?.lg }}
            span={{
              xs: '100%',
            }}
          >
            <Heading offset={1}>{currentStep?.label}</Heading>
            <p>{currentStep?.description}</p>
          </Grid.Col>
          {!isManual && (
            <Grid.Col
              span={{
                xs: '50%',
              }}
            >
              <EntranceCriteria
                {...{
                  filters: formValues?.entranceCriteria,
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col
            span={{
              xs: '50%',
            }}
          >
            <RemediationActions
              {...{
                scopeCode: formValues?.remediation?.actionPathScopeCode,
                remediationType: formValues?.remediation?.remediationMethod,
                assignments: formValues?.remediation?.assignments.map((assignment) => {
                  const theAssignment = { ...assignment };
                  const theAction = assets?.ListActions?.data?.content?.find((action) => {
                    return action?.id === theAssignment?.actionId;
                  });

                  theAssignment.actionName = theAction?.name;
                  return theAssignment;
                }),
              }}
            />
          </Grid.Col>
        </Grid>
      </Visibility>
      <Visibility
        accessor="ExitCriteria"
        enabledEnvironments={['Local', 'Development']}
        disabledEnvironments={['Stage', 'Production']}
      >
        <Grid>
          <Grid.Col
            css={{ paddingTop: themeConfiguration?.theme?.space?.lg }}
            span={{
              xs: '100%',
            }}
          >
            <Heading offset={1}>{currentStep?.label}</Heading>
            <p>{currentStep?.description}</p>
          </Grid.Col>
          {!isManual && [
            <Grid.Col
              span={{
                xs: '50%',
              }}
            >
              <FilterCriteria
                {...{
                  filterKey: 'entranceCriteria',
                  filters: formValues?.entranceCriteria,
                }}
              />
            </Grid.Col>,
            <Grid.Col
              span={{
                xs: '50%',
              }}
            >
              <FilterCriteria
                {...{
                  filterKey: 'exitCriteria',
                  filters: formValues?.exitCriteria,
                }}
              />
            </Grid.Col>,
          ]}
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <RemediationActions
              {...{
                scopeCode: formValues?.remediation?.actionPathScopeCode,
                remediationType: formValues?.remediation?.remediationMethod,
                assignments: formValues?.remediation?.assignments.map((assignment) => {
                  const theAssignment = { ...assignment };
                  const theAction = assets?.ListActions?.data?.content?.find((action) => {
                    return action?.id === theAssignment?.actionId;
                  });

                  theAssignment.actionName = theAction?.name;
                  return theAssignment;
                }),
              }}
            />
          </Grid.Col>
        </Grid>
      </Visibility>
    </ErrorHandler>
  );
};

ReviewConfirm.propTypes = {
  assets: PropTypes.shape({
    ListActions: PropTypes.shape({
      data: PropTypes.shape({
        content: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
          })
        ),
      }),
    }),
  }),
  currentStep: PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.string,
  }),
  form: PropTypes.shape({
    getValues: PropTypes.func,
  }),
  isManual: PropTypes.bool,
};

ReviewConfirm.defaultProps = {
  assets: {},
  currentStep: {},
  form: {},
  isManual: false,
};
