import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Badge } from '@abyss/web/ui/Badge';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { FilterCriteria } from '@src/components/FilterCriteria';
import { isArray, isEmpty, isUndefined, merge } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { RiskCodesTooltip, SourcesTooltip } from '@src/tooltips';
import { Table as TableComponent } from '@src/components/Table-query';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { uniq } from 'lodash/array';
import { useApi } from '@src/context/Api';
import { useInterval } from '@abyss/web/hooks/useInterval';
import { Visibility } from '@src/components/Visibility';
import { TotalRecords } from './components/Header/components/TotalRecords';
import { Header } from './components/Header';
import { ExportStatus } from './components/Header/components/ExportStatus';
import { ExpansionRow } from './components/ExpansionRow';
import configuration from './includes/configuration.json';

/**
 * Table: RiskRecords
 *
 * @TODO - Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const {
    allowExport,
    commonCriteria,
    currentEntity,
    entranceCriteria,
    showAnalyzeRisks,
    showFilters,
    showHeaderLeft,
    showHeaderTop,
    showTotalRecords,
  } = props;

  const { useApiQuery, clearApiCache } = useApi();

  const [ListRiskRecords, { data, isLoading, isFetching, error }] = useApiQuery('ListRiskRecords');
  const [ListRiskCodes, { data: riskCodes, isLoading: riskCodesLoading, isFetching: riskCodesFetching }] =
    useApiQuery('ListRiskCodes');
  const [GetRiskRecordsCount, { data: riskRecordCount }] = useApiQuery('GetRiskRecordsCount');
  const [GetExportDetails, { data: exportData, refetch: exportRefetch }] = useApiQuery('GetExportDetails');

  const [pollingRate, setPollingRate] = useState(null);

  const [filters, setFilters] = useState([]);

  /**
   * Get the list of risk codes if not already fetched.
   */
  useEffect(() => {
    if (isUndefined(riskCodes)) {
      ListRiskCodes();
    }
  }, [riskCodes]);

  /**
   * Set the entrance criteria as the initial filters if not already set.
   */
  useEffect(() => {
    if (!isEmpty(entranceCriteria) && isEmpty(filters)) {
      setFilters(entranceCriteria);
    }
  }, [entranceCriteria]);

  /**
   * Get the total number of risk records if not already fetched.
   */
  useEffect(() => {
    if (showHeaderTop === true && showTotalRecords === true) {
      if (isUndefined(riskRecordCount) && !isEmpty(filters)) {
        GetRiskRecordsCount({ filters });
      }
    }
  }, [riskRecordCount, filters, showTotalRecords, showHeaderTop]);

  /**
   * Get export details if not already fetched.
   */
  useEffect(() => {
    if (allowExport === true && showHeaderTop === true && showHeaderLeft === true) {
      if (isUndefined(exportData) && currentEntity?.type === 'actionPath' && currentEntity?.id) {
        GetExportDetails({ actionPathId: currentEntity?.id });
      }
    }
  }, [exportData, currentEntity, showHeaderTop, showHeaderLeft, allowExport]);

  /**
   * Start polling for status changes.
   */
  useEffect(() => {
    if (allowExport === true && showHeaderTop === true && showHeaderLeft === true) {
      if (['RUNNING', 'SCHEDULED'].includes(exportData?.status)) {
        setPollingRate(15000);
      } else {
        setPollingRate(null);
      }
    }
  }, [exportData, showHeaderTop, showHeaderLeft, allowExport]);

  useInterval(async () => {
    if (allowExport === true && showHeaderTop === true && showHeaderLeft === true) {
      clearApiCache(['GetExportDetails']);
      GetExportDetails({ actionPathId: currentEntity?.id });
    }
  }, pollingRate);

  /**
   * renderCellEID
   *
   * renders a link to open in a remote system (HCM - Health Care Matrix)
   *
   * @param cell
   * @returns {JSX.Element}
   */
  const renderCellEID = ({ cell }) => {
    return <Link href={`/analysis/eid-search/${cell.value}/`}>{cell.value}</Link>;
  };

  /**
   * renderCellRiskScore
   *
   * @TODO - Needs description.
   *
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellRiskScore = ({ cell }) => {
    const codeNumbers = cell?.value?.split('-');

    const letter = codeNumbers.shift().toUpperCase();

    const codeDescriptions = [];

    codeNumbers.forEach((codeNumber) => {
      const match = riskCodes?.find((riskCode) => {
        return riskCode?.codeId === String(codeNumber);
      });

      if (!isUndefined(match) && !codeDescriptions.includes(match)) {
        codeDescriptions.push(match);
      }
    });

    if (letter === 'R') {
      return (
        <Layout.Group space={themeConfiguration?.theme?.space?.md}>
          <Badge variant="error" outline>
            <Tooltip placement="auto" content={<RiskCodesTooltip codeDetails={codeDescriptions} />}>
              <div>{cell?.value}</div>
            </Tooltip>
          </Badge>
        </Layout.Group>
      );
    }

    if (letter === 'Y') {
      return (
        <Layout.Group space={themeConfiguration?.theme?.space?.md}>
          <Badge variant="warning" outline>
            <Tooltip placement="auto" content={<RiskCodesTooltip codeDetails={codeDescriptions} />}>
              <div>{cell?.value}</div>
            </Tooltip>
          </Badge>
        </Layout.Group>
      );
    }

    if (letter === 'G') {
      return (
        <Layout.Group space={themeConfiguration?.theme?.space?.md}>
          <Badge variant="success" outline>
            {cell?.value}
          </Badge>
        </Layout.Group>
      );
    }

    return cell.value;
  };

  /**
   * renderCellTrustedSources
   *
   * @TODO - Needs description.
   *
   * @param row
   * @returns {JSX.Element|string}
   */
  const renderCellTrustedSources = ({ row }) => {
    const sources = [];

    row?.original?.ireRiskRecord?.trustedRecordSources?.forEach((trustedSource) => {
      if (!sources.includes(trustedSource?.source)) {
        sources.push(trustedSource?.source);
      }
    });

    return (
      <Tooltip placement="auto" content={<SourcesTooltip sources={sources} />}>
        <span className="truncate">{sources.join(', ')}</span>
      </Tooltip>
    );
  };

  /**
   * renderCellUntrustedSources
   *
   * @TODO - Needs description.
   *
   * @param row
   * @returns {JSX.Element|string}
   */
  const renderCellUntrustedSources = ({ row }) => {
    if (isArray(row?.original?.ireRiskRecord?.remediationFindings)) {
      let sources = [];

      row?.original?.ireRiskRecord?.remediationFindings.forEach((attribute) => {
        if (isArray(attribute.untrustedRecordSources)) {
          attribute.untrustedRecordSources.forEach((untrustedSource) => {
            if (!sources.includes(untrustedSource.source)) {
              sources.push(untrustedSource.source);
            }
          });
        }
      });

      sources = uniq(sources);

      return (
        <Tooltip placement="auto" content={<SourcesTooltip sources={sources} />}>
          <span className="truncate">{sources.join(', ')}</span>
        </Tooltip>
      );
    }
    return '';
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns.map((item) => {
      const column = item;

      if (column.Header === 'EID') {
        column.Cell = renderCellEID;
      }

      if (column.Header === 'Risk Score') {
        column.Cell = renderCellRiskScore;
      }

      if (column.Header === 'Trusted Sources') {
        column.Cell = renderCellTrustedSources;
      }

      if (column.Header === 'Untrusted Sources') {
        column.Cell = renderCellUntrustedSources;
      }

      return column;
    });
  }, []);

  if (isEmpty(filters)) {
    return null;
  }

  return (
    <ErrorHandler location="src/tables/RiskRecords/Table.jsx">
      <TableComponent
        {...{
          allowExport,
          error,
          exportDetails: exportData,
          isLoading: isLoading || isFetching || riskCodesLoading || riskCodesFetching,
          pollingRate,
          requestArgs: { page: 0, size: 25, sort: 'eid,asc', filters },
          requestFunction: ListRiskRecords,
          requestKey: ListRiskRecords,
          riskCodes,
          riskRecordCount,
          rows: data?.content,
          setPollingRate,
          totalPages: data?.totalPages,
          totalRecords: data?.totalElements,
          columns,
          configuration: merge({}, configuration, {
            renderSubComponent: ({ original }) => {
              return <ExpansionRow row={original} />;
            },
          }),
          headerTop: (
            <React.Fragment>
              {showHeaderTop === true && (
                <Layout.Group space={themeConfiguration?.theme?.space?.sm}>
                  {showTotalRecords === true && <TotalRecords riskRecordCount={riskRecordCount} />}

                  {showTotalRecords === true && allowExport === true && (
                    <Divider orientation="vertical" width={1} height={24} />
                  )}

                  {allowExport === true && <ExportStatus exportDetails={exportData} />}

                  {allowExport === true && showFilters === true && (
                    <Divider orientation="vertical" width={1} height={24} />
                  )}

                  {showFilters === true && [
                    <Visibility
                      accessor="FilterCriteria-current"
                      enabledEnvironments={['Stage', 'Production']}
                      disabledEnvironments={['Local', 'Development']}
                    >
                      <FilterCriteria filters={filters} label="Entrance Criteria" />
                    </Visibility>,
                    <Visibility
                      accessor="CommonCriteria"
                      enabledEnvironments={['Local', 'Development']}
                      disabledEnvironments={['Stage', 'Production']}
                    >
                      <FilterCriteria filters={filters} label="Additional Criteria" />
                      <React.Fragment>
                        {!isEmpty(commonCriteria) && <Divider orientation="vertical" width={1} height={24} />}
                      </React.Fragment>
                      <FilterCriteria filters={commonCriteria} label="Common Criteria" />
                    </Visibility>,
                  ]}
                </Layout.Group>
              )}
            </React.Fragment>
          ),
          headerLeft: (
            <React.Fragment>
              {showHeaderLeft === true && (
                <Header
                  showAnalyzeRisks={showAnalyzeRisks}
                  currentEntity={currentEntity}
                  filters={filters}
                  exportDetails={exportData}
                  pollingRate={pollingRate}
                  setPollingRate={setPollingRate}
                  riskRecordCount={riskRecordCount}
                  allowExport={allowExport}
                  exportRefetch={exportRefetch}
                />
              )}
            </React.Fragment>
          ),
        }}
      />
    </ErrorHandler>
  );
};

Table.propTypes = {
  currentEntity: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  allowExport: PropTypes.bool,
  showHeaderTop: PropTypes.bool,
  showHeaderLeft: PropTypes.bool,
  showTotalRecords: PropTypes.bool,
  showFilters: PropTypes.bool,
  showAnalyzeRisks: PropTypes.bool,
  entranceCriteria: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.string,
      condition: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    })
  ),
  commonCriteria: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

Table.defaultProps = {
  currentEntity: {},
  allowExport: true,
  showHeaderTop: true,
  showHeaderLeft: true,
  showTotalRecords: true,
  showFilters: true,
  showAnalyzeRisks: true,
  entranceCriteria: [],
  commonCriteria: [],
};
