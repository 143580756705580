import PropTypes from 'prop-types';
import React from 'react';
import { Chip } from '@abyss/web/ui/Chip';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isArray, isEmpty } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Styles } from './includes/styles';
import fields from '../Filters/components/Table/includes/fields.json';

/**
 * FilterCriteria
 *
 * Shows the currently applied filters.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const FilterCriteria = (props) => {
  const { filters, showLabel, label } = props;

  /**
   * getLabel
   *
   * @TODO Needs description.
   *
   * @param theCondition
   * @returns {*}
   */
  const getLabel = (theCondition) => {
    const matchedCondition = fields?.condition?.options?.find((condition) => {
      return condition?.value === theCondition;
    });
    return matchedCondition?.label;
  };

  /**
   * theFilter
   *
   * @TODO Needs description.
   *
   * @param theFilter
   * @returns {*}
   */
  const getFilter = (theFilter) => {
    const matchedFilter = fields?.column?.options?.find((filter) => {
      return filter?.value === theFilter;
    });
    return matchedFilter?.label;
  };

  /**
   * getValue
   *
   * @TODO Needs description.
   *
   * @param theValue
   * @returns {*}
   */
  const getValue = (theValue) => {
    let value = theValue;

    if (isArray(theValue)) {
      value = theValue.join(', ');
    }

    return value;
  };

  if (isEmpty(filters)) {
    return null;
  }

  return (
    <ErrorHandler location="src/components/FilterCriteria/FilterCriteria.jsx">
      <Styles>
        <Layout.Group css={{ margin: 0 }}>
          {showLabel === true && !isEmpty(label) && <Text fontWeight="bold">{label}:</Text>}

          {label === 'Common Criteria' ? (
            <Chip
              key={label}
              text={<Layout.Group space={6}>{filters?.join(', \n')}</Layout.Group>}
              icon={<IconSymbol icon="settings" variant="outlined" />}
            />
          ) : (
            <React.Fragment>
              {filters?.map((filter) => {
                return (
                  <Chip
                    key={`${JSON.stringify(filter)}`}
                    text={
                      <Layout.Group space={6}>
                        <div>{getFilter(filter?.column)}:</div>
                        {getLabel(filter?.condition)}
                        <div>&quot;{getValue(filter?.value)}&quot;</div>
                      </Layout.Group>
                    }
                    icon={
                      <React.Fragment>
                        {filter?.column === 'TAGS' && <IconSymbol icon="label" variant="filled" />}
                        {filter?.column.toLowerCase().includes('date') && (
                          <IconSymbol icon="calendar_month" variant="outlined" />
                        )}
                        {filter?.column.toLowerCase().includes('count') && (
                          <IconSymbol icon="numbers" variant="outlined" />
                        )}
                        {filter?.column === 'ACTION_STATUS' && <IconSymbol icon="help_outline" variant="outlined" />}
                      </React.Fragment>
                    }
                  />
                );
              })}
            </React.Fragment>
          )}
        </Layout.Group>
      </Styles>
    </ErrorHandler>
  );
};

FilterCriteria.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.string,
      condition: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
    })
  ),
  showLabel: PropTypes.bool,
  label: PropTypes.string,
};

FilterCriteria.defaultProps = {
  filters: [],
  showLabel: true,
  label: '',
};
