import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from '@abyss/web/ui/Badge';
import { FilterCriteria as Table } from '@src/tables';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import { Styles } from './includes/styles';

/**
 * Widget: FilterCriteria
 *
 * @TODO Needs description
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const FilterCriteria = (props) => {
  const { filters, filterKey } = props;

  let title = '';

  if (filterKey === 'entranceCriteria') {
    title = 'Entrance Criteria';
  }

  if (filterKey === 'exitCriteria') {
    title = 'Exit Criteria';
  }

  return (
    <ErrorHandler location="src/widgets/FilterCriteria/FilterCriteria.jsx">
      <Styles>
        <Widget
          title={title}
          description=""
          button={
            <Badge outline variant="info">
              2.1M records
            </Badge>
          }
        >
          <Table rows={filters || []} filterKey={filterKey} />
        </Widget>
      </Styles>
    </ErrorHandler>
  );
};

FilterCriteria.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.string,
      condition: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    })
  ),
  filterKey: PropTypes.string,
};

FilterCriteria.defaultProps = {
  filters: [],
  filterKey: 'filters',
};
