import PropTypes from 'prop-types';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Modal } from '@abyss/web/ui/Modal';
import { CreateForm } from '../../forms/Create';
import { useSave } from '../../../hooks/useSave';

/**
 * CreateModal
 *
 * Prompts the user with a popup window allowing them to create a new set of common criteria filters.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Create = (props) => {
  const { assets, isOpen, setIsOpen, refetch } = props;

  const handleSave = useSave();

  /**
   * handleClose
   *
   * Handles the closing of the modal.
   *
   * @returns {Promise<void>}
   */
  const handleClose = () => {
    setIsOpen(false);
  };

  /**
   * handleCreate
   *
   * Saves the
   *
   * @param payload
   * @returns {Promise<void>}
   */
  const handleCreate = async (payload = {}) => {
    await handleSave('create', payload);
    refetch();
    setIsOpen(false);
  };

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CommonCriteria/components/modals/Create/Create.jsx">
      <Modal title="Create Common Criteria" isOpen={isOpen} onClose={handleClose} size="full">
        <Modal.Section>
          <CreateForm assets={assets} handleSave={handleCreate} handleClose={handleClose} />
        </Modal.Section>
      </Modal>
    </ErrorHandler>
  );
};

Create.propTypes = {
  assets: PropTypes.shape({
    actionPaths: PropTypes.shape({
      create: PropTypes.string,
    }),
    actionStatuses: PropTypes.shape({
      create: PropTypes.string,
    }),
    tags: PropTypes.arrayOf(PropTypes.string),
  }),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  refetch: PropTypes.func,
};

Create.defaultProps = {
  assets: {},
  isOpen: false,
  setIsOpen: () => {},
  refetch: () => {},
};
