import { useApi } from '@src/context/Api';
import { useCallback } from 'react';
import { useToast } from '@abyss/web/hooks/useToast';
import { config } from '@abyss/web/tools/config';

/**
 * useUpdateStatus
 *
 * Makes an API request to activate the common criteria.
 *
 * @returns {(function(*): Promise<void>)|*}
 */
export const useUpdateStatus = () => {
  const { useApiMutation } = useApi();
  const [SaveCommonCriteria] = useApiMutation('SaveCommonCriteria');

  const { toast } = useToast();

  return useCallback(async (submittedValues) => {
    try {
      let status;

      if (submittedValues?.isActive === true) {
        status = 'Activat';
      }

      if (submittedValues?.isActive === false) {
        status = 'Deactivat';
      }

      const toastId = `common-criteria-${status.toLowerCase()}e`;

      toast.show({
        id: `${toastId}-info`,
        title: `${status}ing Common Criteria...`,
        message: `Preparing to ${status.toLowerCase()}e the common criteria.`,
        isLoading: true,
        variant: 'info',
        autoClose: false,
      });

      await SaveCommonCriteria(submittedValues, {
        onSuccess: () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-success`,
            title: `${status}ed Common Criteria`,
            message: `${submittedValues?.name} has been ${status.toLowerCase()}ed.`,
            variant: 'success',
          });
        },
        onError: () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-error`,
            title: `${status}ing Common Criteria Failed`,
            message: `Unable to ${status}e ${submittedValues?.name}.`,
            variant: 'error',
          });
        },
      });
    } catch (error) {
      let theError = error;

      if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
        theError = JSON.stringify(error);
      }

      console.error(
        'src/routes/private/Admin/screens/CommonCriteria/hooks/useUpdateStatus.js -> useUpdateStatus():',
        theError
      );
    }
  }, []);
};
