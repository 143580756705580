import React, { useCallback, useState } from 'react';
import { useForm } from '@abyss/web/hooks/useForm';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { TextInput } from '@abyss/web/ui/TextInput';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Filters } from '@src/components/Filters';
import { Divider } from '@abyss/web/ui/Divider';
import { Layout } from '@abyss/web/ui/Layout';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import PropTypes from 'prop-types';

/**
 * form: Create
 *
 * Displays a form to update a set of common criteria filters.
 *
 * @returns {Element}
 * @constructor
 */
export const Create = (props) => {
  const { handleSave, handleClose } = props;

  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = {
    id: '',
    name: '',
    filters: [],
    isActive: true,
  };

  const form = useForm({ defaultValues });

  const { isDirty, isValid, isSubmitting } = form?.formState;

  /**
   * handleSubmit
   *
   * Calls a remote API to save the common criteria.
   *
   * @returns {Promise<void>}
   */
  const handleSubmit = useCallback(
    async (submittedValues) => {
      if (!isLoading && isValid && !isSubmitting && isDirty) {
        setIsLoading(true);
        await handleSave(submittedValues);
        setIsLoading(false);
      }
    },
    [isLoading, isDirty, isValid, isSubmitting, form?.formState?.errors]
  );

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CommonCriteria/components/forms/Create/Create.jsx">
      <FormProvider state={form} autoComplete="off" onSubmit={handleSubmit}>
        <Grid>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <TextInput
              model="name"
              label="Name"
              width={`${Number(themeConfiguration?.theme?.space?.xl?.replace('px', '')) * 8}px`}
            />
          </Grid.Col>
          <Grid.Col span={{ xs: '100%' }}>
            <Filters form={form} isLoading={isLoading} />
          </Grid.Col>
          <Grid.Col span={{ xs: '100%' }}>
            <Divider height={1} />
            <Layout.Group alignLayout="right">
              <Button variant="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="solid" type="submit" isDisabled={!isDirty || !isValid || isLoading}>
                Create
              </Button>
            </Layout.Group>
          </Grid.Col>
        </Grid>
      </FormProvider>
    </ErrorHandler>
  );
};

Create.propTypes = {
  handleSave: PropTypes.func,
  handleClose: PropTypes.func,
};

Create.defaultProps = {
  handleSave: () => {},
  handleClose: () => {},
};
