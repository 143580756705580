import React from 'react';
import { Layout } from '@abyss/web/ui/Layout';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { AnalyzeRisksButton } from '@src/components/AnalyzeRisksButton';
import PropTypes from 'prop-types';
import { Flex } from '@abyss/web/ui/Flex';
import { ExportSharePoint } from './components/ExportSharePoint';
import { ViewSharePoint } from './components/ViewSharePoint';

/**
 * Header
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Header = (props) => {
  const {
    currentEntity,
    allowExport,
    exportDetails,
    exportRefetch,
    filters,
    pollingRate,
    riskRecordCount,
    setPollingRate,
    showAnalyzeRisks,
  } = props;

  return (
    <ErrorHandler location="src/tables/RiskRecords/components/Header/Header.jsx">
      <Layout.Group
        alignLayout="left"
        alignItems="bottom"
        css={{ height: '100%' }}
        space={themeConfiguration?.theme?.space?.sm}
      >
        <Flex alignItems="top">
          {allowExport === true && [
            <div>
              <ExportSharePoint
                currentEntity={currentEntity}
                exportDetails={exportDetails}
                setPollingRate={setPollingRate}
                pollingRate={pollingRate}
                riskRecordCount={riskRecordCount}
                exportRefetch={exportRefetch}
              />
            </div>,
            <div>
              <Divider orientation="vertical" width={1} height={24} />
            </div>,
          ]}

          {allowExport === true && (
            <div>
              <ViewSharePoint currentEntity={currentEntity} exportDetails={exportDetails} />
            </div>
          )}

          {allowExport === true && showAnalyzeRisks && (
            <div>
              <Divider orientation="vertical" width={1} height={24} />
            </div>
          )}

          {showAnalyzeRisks === true && (
            <div>
              <AnalyzeRisksButton entranceCriteria={filters} variant="link" />
            </div>
          )}
        </Flex>
      </Layout.Group>
    </ErrorHandler>
  );
};

Header.propTypes = {
  allowExport: PropTypes.bool,
  currentEntity: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  exportDetails: PropTypes.shape({
    exportType: PropTypes.string,
    exportName: PropTypes.string,
    exportDescription: PropTypes.string,
    exportColumns: PropTypes.arrayOf(PropTypes.string),
    exportFilters: PropTypes.arrayOf(
      PropTypes.shape({
        column: PropTypes.string,
        condition: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      })
    ),
  }),
  exportRefetch: PropTypes.func,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.string,
      condition: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    })
  ),
  pollingRate: PropTypes.number,
  riskRecordCount: PropTypes.number,
  setPollingRate: PropTypes.func,
  showAnalyzeRisks: PropTypes.bool,
};

Header.defaultProps = {
  allowExport: false,
  currentEntity: {
    id: '',
    name: '',
  },
  exportDetails: {
    exportType: '',
    exportName: '',
    exportDescription: '',
    exportColumns: [],
    exportFilters: [],
  },
  exportRefetch: () => {},
  filters: [],
  pollingRate: 0,
  riskRecordCount: 0,
  setPollingRate: () => {},
  showAnalyzeRisks: false,
};
