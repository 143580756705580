import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Table as TableComponent } from '@src/components/Table-static';
import { ErrorHandler } from '@src/components/ErrorHandler';
import configuration from './includes/configuration.json';

/**
 * Table: Actions
 *
 * Displays a list of actions within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { rows } = props;

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns;
  }, []);

  return (
    <ErrorHandler location="src/tables/ActionPaths/Actions/Table.jsx">
      <TableComponent
        {...{
          rows,
          columns,
          configuration,
          dataKey: `actionPaths-actions`,
        }}
      />
    </ErrorHandler>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})),
};

Table.defaultProps = {
  rows: [],
};
